<template>
  <vx-card :title="title">
    <div>
      <customer-category @data="setCustomerCategoryId"></customer-category>
      <territory @data="setTerritoryId"></territory>
      <!-- <vs-button
        class="mt-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
      >Create</vs-button> -->
    </div>
    <vs-tabs>
      <vs-tab label="Open">
        <div class="tab-text">
          <div>
            <div class="vx-row mb-12">
              <div v-bind:class="[detail ? detailShow : '', detailHide]">
                <data-table
                  :detail="detail"
                  @close="handleClose"
                  @edit="handleEdit"
                  :customerCategoryId="customerCategoryId"
                  :territorryId="territorryId"
                  :statusApproval="'1'"
                ></data-table>
              </div>
              <!-- form -->
              <transition name="detail-fade">
                <div
                  v-if="detail"
                  v-bind:class="[detail ? detailShow : '', detailHide]"
                >
                  <vs-row>
                    <vs-col
                      style="padding-bottom: 8px"
                      vs-offset="8"
                      vs-type="flex"
                      vs-justify="rigth"
                      vs-align="rigth"
                      vs-w="4"
                    >
                      <vs-button
                        class="ml-auto"
                        size="small"
                        v-on:click="handleClose"
                        color="grey"
                        icon-pack="feather"
                        icon="icon-x-square"
                        >Close</vs-button
                      >
                    </vs-col>
                    <vs-col
                      class="vertical-divider"
                      vs-offset="0"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="1"
                    >
                      <vs-button
                        size="small"
                        v-on:click="handleClose"
                        color="grey"
                        icon-pack="feather"
                        icon="icon-x-square"
                      ></vs-button>
                    </vs-col>

                    <!-- form component -->
                    <form-component
                      @close="handleClose"
                      :customerId="customerId"
                      :territoryData="territory"
                      :showButton="true"
                      :showButtonReopen="false"
                      @tab="setTabActive"
                    >
                    </form-component>
                  </vs-row>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="tab-text">
          <div>
            <div class="vx-row mb-12">
              <div v-bind:class="[detail ? detailShow : '', detailHide]">
                <data-table
                  :detail="detail"
                  @close="handleClose"
                  @edit="handleEdit"
                  :customerCategoryId="customerCategoryId"
                  :territorryId="territorryId"
                  :statusApproval="'2'"
                ></data-table>
              </div>
              <!-- form -->
              <transition name="detail-fade">
                <div
                  v-if="detail"
                  v-bind:class="[detail ? detailShow : '', detailHide]"
                >
                  <vs-row>
                    <vs-col
                      style="padding-bottom: 8px"
                      vs-offset="8"
                      vs-type="flex"
                      vs-justify="rigth"
                      vs-align="rigth"
                      vs-w="4"
                    >
                      <vs-button
                        class="ml-auto"
                        size="small"
                        v-on:click="handleClose"
                        color="grey"
                        icon-pack="feather"
                        icon="icon-x-square"
                        >Close</vs-button
                      >
                    </vs-col>
                    <vs-col
                      class="vertical-divider"
                      vs-offset="0"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="1"
                    >
                      <vs-button
                        size="small"
                        v-on:click="handleClose"
                        color="grey"
                        icon-pack="feather"
                        icon="icon-x-square"
                      ></vs-button>
                    </vs-col>

                    <!-- form component -->
                    <form-component
                      @close="handleClose"
                      :customerId="customerId"
                      :territoryData="territory"
                      :showButton="false"
                      :showButtonReopen="false"
                      @tab="setTabActive"
                    >
                    </form-component>
                  </vs-row>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="tab-text">
          <div>
            <div class="vx-row mb-12">
              <div v-bind:class="[detail ? detailShow : '', detailHide]">
                <data-table
                  :detail="detail"
                  @close="handleClose"
                  @edit="handleEdit"
                  :customerCategoryId="customerCategoryId"
                  :territorryId="territorryId"
                  :statusApproval="'03'"
                ></data-table>
              </div>
              <!-- form -->
              <transition name="detail-fade">
                <div
                  v-if="detail"
                  v-bind:class="[detail ? detailShow : '', detailHide]"
                >
                  <vs-row>
                    <vs-col
                      style="padding-bottom: 8px"
                      vs-offset="8"
                      vs-type="flex"
                      vs-justify="rigth"
                      vs-align="rigth"
                      vs-w="4"
                    >
                      <vs-button
                        class="ml-auto"
                        size="small"
                        v-on:click="handleClose"
                        color="grey"
                        icon-pack="feather"
                        icon="icon-x-square"
                        >Close</vs-button
                      >
                    </vs-col>
                    <vs-col
                      class="vertical-divider"
                      vs-offset="0"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="1"
                    >
                      <vs-button
                        size="small"
                        v-on:click="handleClose"
                        color="grey"
                        icon-pack="feather"
                        icon="icon-x-square"
                      ></vs-button>
                    </vs-col>

                    <!-- form component -->
                    <form-component
                      @close="handleClose"
                      :customerId="customerId"
                      :territoryData="territory"
                      :showButton="false"
                      :showButtonReopen="true"
                      @tab="setTabActive"
                    >
                    </form-component>
                  </vs-row>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import FormComponent from "./form";
import CustomerCategory from "./CustomerCategory";
import Territory from "./Territory";
import DataTable from "./DataTable";
import PerfectScrollbar from "vue-perfect-scrollbar";
export default {
  components: {
    FormComponent,
    CustomerCategory,
    Territory,
    PerfectScrollbar,
    DataTable,
  },
  data() {
    return {
      title: "Customer Approval",
      baseUrl: "/api/v1/master/customer",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      deleteId: null,
      customerId: null,
      statusApproval: null,
      customerCategoryId: null,
      territorryId: null,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7,
      },
    };
  },
  methods: {
    setTabActive(val) {
      this.$emit("tab", val);
    },
    handleClose() {
      this.detail = false;
    },
    // handleCreate() {
    //   this.customerId = null;
    //   this.statusApproval = "1";
    //   this.detail = true;
    // },
    handleEdit(id, status_approval, territory) {
      this.customerId = id;
      this.statusApproval = status_approval;
      this.territory = territory
      this.detail = true;
    },
    setCustomerCategoryId(val) {
      this.customerCategoryId = val;
    },
    setTerritoryId(val) {
      this.territorryId = val;
    },
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.ps {
  height: 800px;
}
</style>