<template>
  <div class="vx-col w-11/12">
    <!-- <perfect-scrollbar> -->
    <div class="vx-col w-11/12 ml-auto mr-auto">
      <span>
        <b>View Customer</b>
      </span>
      <!-- <div class="vx-row mb-6" v-if="customerId != null">
        <div class="vx-col w-full">
          <vs-input disabled class="w-full" label="Code" v-model="data.code" />
        </div>
      </div> -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <!-- <vs-input v-if="data.statusApproval == '2'" class="w-full" label="Code" v-model="data.code" readonly/> -->
          <vs-input
            v-if="data.codeExternal == ''"
            class="w-full"
            label="Code"
            v-model="data.code"
            readonly
          />
          <vs-input
            v-else
            class="w-full"
            label="Code"
            v-model="data.codeExternal"
            readonly
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="NOO Code"
            v-model="data.codeNoo"
            readonly
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="External Code"
            v-model="data.codeExternal"
            readonly
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Territory"
            v-model="territoryData"
            readonly
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Name"
            v-validate="'required'"
            name="Name"
            v-model="data.name"
          />
          <span class="text-danger text-sm" v-show="errors.has('Name')">{{
            errors.first("Name")
          }}</span>
        </div>
      </div>
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Legal Name"
            name="Legal Name"
            v-model="data.legalName"
          />
        </div>
      </div> -->
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Search Name"
            name="Search Name"
            v-model="data.searchName"
          />
        </div>
      </div> -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Date of Birth</label>
          <datepicker
            name="Date of Birth"
            label="Date of Birth"
            :inline="false"
            v-model="data.dateOfBirth"
            placeholder="Select Date"
          ></datepicker>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="NIK"
            v-model="data.nik"
            v-validate="'numeric|digits:16'"
            name="NIK"
          />
          <span class="text-danger text-sm" v-show="errors.has('NIK')">{{
            errors.first("NIK")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Nik Name" v-model="data.nik_name" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Status</label>
          <Status :status="data.status" @data="setStatus"></Status>
        </div>
      </div>
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Bussiness Partner</label>
          <bussiness-partner
            :id="data.parentId"
            @parentId="setParentId"
          ></bussiness-partner>
        </div>
      </div> -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Distribution Channel</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="customerCategory.selected"
                :options="customerCategory.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </div>
                </template>
              </multiselect>
            </div>
            <!-- <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToCustomerCategory"
              ></vs-button>
            </div> -->
          </div>

          <span
            class="text-danger text-sm"
            v-show="errors.has('CustomerCategory')"
            >{{ errors.first("CustomerCategory") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 1</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="customerGroup1.selected"
                :options="customerGroup1.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
                :custom-label="codeWithName"
              >
              </multiselect>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('CustomerGroup1')"
            >{{ errors.first("CustomerGroup1") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 2</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="customerGroup2.selected"
                :options="customerGroup2.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
                :custom-label="codeWithName"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 3</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="customerGroup3.selected"
                :options="customerGroup3.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
                :custom-label="codeWithName"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Pricing Group</label>
          <multiselect
            v-model="pricingGroup.selected"
            :options="pricingGroup.options"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
            :custom-label="codeWithName"
          >
            <!-- <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.name }} ({{ props.option.code }})</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.name }} ({{ props.option.code }})</span
                >
              </div>
            </template> -->
          </multiselect>
        </div>
        <!-- <div class="vx-col w-1/6">
            <vs-button
              color="dark"
              type="line"
              icon-pack="feather"
              icon="icon-plus-square"
              @click="pushToPricingGroup"
            ></vs-button>
          </div> -->
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Risk Category</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="riskCategory.selected"
                :options="riskCategory.options"
                placeholder="Type to search"
                track-by="code"
                label="code"
                :max-height="125"
                :custom-label="nameWithCode"
              >
                <!-- <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.code }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.code }}</span>
                  </div>
                </template> -->
              </multiselect>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('RiskCategory')"
            >{{ errors.first("RiskCategory") }}</span
          >
        </div>
      </div>
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Payment Method</label>
          <payment-method
            :id="data.paymentMethodId"
            @data="setPaymentMethodId"
          ></payment-method>
          <span
            class="text-danger text-sm"
            v-show="errors.has('PaymentMethod')"
            >{{ errors.first("PaymentMethod") }}</span
          >
        </div>
      </div> -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Payment Type</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="paymentType.selected"
                :options="paymentType.options"
                placeholder="Type to search"
                :max-height="125"
                disabled
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option }}</span>
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('PaymentType')"
            >{{ errors.first("PaymentType") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Payment Term</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="paymentTerm.selected"
                :options="paymentTerm.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
                disabled
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
            <!-- <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToPaymentTerm"
              ></vs-button>
            </div> -->
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('PaymentTerm')"
            >{{ errors.first("PaymentTerm") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Credit Limit"
            v-model="data.customerLimit.amount"
            name="CreditLimit"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('CreditLimit')"
            >{{ errors.first("CreditLimit") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Delivery Type</label>
          <multiselect
            v-model="deliveryType.selected"
            :options="deliveryType.options"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <span
            class="text-danger text-sm"
            v-show="errors.has('DeliveryType')"
            >{{ errors.first("DeliveryType") }}</span
          >
        </div>
      </div>
      <!-- 
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Territory</label>
          <multiselect
            v-model="territory.selected"
            :options="territory.options"
            :multiple="true"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      -->
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Zone</label>
          <multiselect
            v-model="zone.selected"
            :options="zone.options"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.code }} {{ props.option.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </div>
      </div> -->
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Sales</label>
          <multiselect
            v-model="sales.selected"
            :options="sales.options"
            placeholder="Type to search"
            track-by="Name"
            label="Name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.Name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.Name }}</span>
              </div>
            </template>
          </multiselect>
          <span
            class="text-danger text-sm"
            v-show="errors.has('SalesCustomer')"
            >{{ errors.first("SalesCustomer") }}</span
          >
        </div>
      </div>  -->
      <span class="text-danger text-sm" v-show="errors.has('CountryName')">{{
        errors.first("CountryName")
      }}</span>
      <address-component
        :data="data.address"
        @data="setDataAddress"
      ></address-component>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Transport Zone"
            v-model="data.address.transportZone"
            disabled
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="RT / RW (Format 000/000)"
            @keypress="isNumber($event)"
            v-model="data.rtRw"
            name="RT/RW"
          />
          <span class="text-danger text-sm" v-show="errors.has('RT/RW')">{{
            errors.first("RT/RW")
          }}</span>
        </div>
      </div>
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Street"
            v-model="data.street"
          />
        </div>
      </div> -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Street No" v-model="data.streetNo" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Contact Name"
            v-model="data.contactName"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Phone"
            v-model="data.phone"
            v-validate="'required|numeric|max:13|min:10'"
            name="Phone"
          />
          <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
            errors.first("Phone")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Mobile"
            v-model="data.mobile"
            v-validate="'numeric|max:13|min:10'"
            name="Mobile"
          />
          <span class="text-danger text-sm" v-show="errors.has('Mobile')">{{
            errors.first("Mobile")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Email"
            v-model="data.email"
            v-validate="'email'"
            name="Email"
          />
          <span class="text-danger text-sm" v-show="errors.has('Email')">{{
            errors.first("Email")
          }}</span>
        </div>
      </div>
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            v-if="customerId != null"
            class="w-full"
            label="Password"
            v-model="data.password"
            name="password"
            type="password"
          />
          <vs-input
            v-else
            class="w-full"
            label="Password"
            v-validate="'required'"
            v-model="data.password"
            name="password"
            type="password"
          />
          <span class="text-danger text-sm" v-show="errors.has('Password')">{{
            errors.first("Password")
          }}</span>
        </div>
      </div> -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Fax"
            v-model="data.fax"
            v-validate="'numeric'"
            name="Fax"
          />
          <span class="text-danger text-sm" v-show="errors.has('Fax')">{{
            errors.first("Fax")
          }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Priority</label>
          <multiselect
            v-model="priority.selected"
            :options="priority.options"
            placeholder="Type to search"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Note"
            name="Note"
            v-model="data.note"
          />
        </div>
      </div>
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Reason"
            name="Reason"
            v-model="data.reason"
          />
        </div>
      </div> -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Region SD</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="regionSd.selected"
                :options="regionSd.options"
                placeholder="Type to search"
                track-by="code"
                label="code"
                :max-height="125"
                :custom-label="nameWithCode"
              >
              </multiselect>
              <span
                class="text-danger text-sm"
                v-show="errors.has('RegionSd')"
                >{{ errors.first("RegionSd") }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <b>History Approval</b>
          <template>
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in this.data.reason"
            >
              <vs-td class="whitespace-no-wrap">
                {{ tr }}
              </vs-td>
            </vs-tr>
          </template>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Invoice Model</label>
          <br />
          <ul class="centerx">
            <li>
              <vs-radio
                v-model="data.invoiceModel"
                vs-value="When Shipping"
                disabled
                >When Shipping</vs-radio
              >&nbsp;&nbsp;&nbsp;
            </li>
            <li>
              <vs-radio
                v-model="data.invoiceModel"
                vs-value="Separated"
                disabled
                >Separated</vs-radio
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Tax Collector</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isTaxCollector"
            disabled
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Tax Exempt</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isTaxExempt"
            disabled
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">PKP</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isPkp"
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div>
      <template>
        <div v-if="data.isPkp == true">
          <div class="vx-row mb-3">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="NPWP"
                v-model="data.npwp"
                name="NPWP"
                @input="autoFormatNPWP"
              />
              <span class="text-danger text-sm" v-show="errors.has('NPWP')">{{
                errors.first("NPWP")
              }}</span>
            </div>
          </div>
          <!-- <div class="vx-row mb-3">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="TDP"
                v-model="data.tdp"
                name="TDP"
              />
            </div>
          </div> -->
          <div class="vx-row mb-3">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="NPWP Name"
                v-model="data.npwpName"
              />
            </div>
          </div>
          <div class="vx-row mb-3">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="SPPKP"
                v-model="data.sppkp"
                name="SPPKP"
              />
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="Tax Address"
                v-model="data.taxAddress"
                name="TaxAddress"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('TaxAddress')"
                >{{ errors.first("TaxAddress") }}</span
              >
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-full ml-6">
              <vs-input
                class="w-full"
                label="Domisili"
                v-model="data.domisili"
                name="domisili"
              />
            </div>
          </div>
        </div>
      </template>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>KTP Image</span>
        </div>
        <div class="vx-col w-full">
          <input
            name="file_ktp"
            class="w-full inputx"
            type="file"
            ref="file_ktp"
            accept=".jpg, .jpeg, .png"
            @change="onImageChange($event, 'ktp')"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full" @click="showImage('KTP')">
          <img style="max-height: 170px; max-width: auto" :src="data.urlKtp" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>Outlet Image</span>
        </div>
        <div class="vx-col w-full">
          <input
            name="file_outlet"
            class="w-full inputx"
            type="file"
            ref="file_outlet"
            accept=".jpg, .jpeg, .png"
            @change="onImageChange($event, 'outlet')"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <img
            style="max-height: 170px; max-width: auto"
            :src="data.urlOutlet"
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>NPWP Image</span>
        </div>
        <div class="vx-col w-full">
          <input
            name="file_npwp"
            class="w-full inputx"
            type="file"
            ref="file_npwp"
            accept=".jpg, .jpeg, .png"
            @change="onImageChange($event, 'npwp')"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full" @click="showImage('NPWP')">
          <img style="max-height: 170px; max-width: auto" :src="data.urlNpwp" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>SPPKP Image</span>
        </div>
        <div class="vx-col w-full">
          <input
            name="file_sppkp"
            class="w-full inputx"
            type="file"
            ref="file_sppkp"
            accept=".jpg, .jpeg, .png"
            @change="onImageChange($event, 'sppkp')"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full" @click="showImage('SPPKP')">
          <img style="max-height: 170px; max-width: auto" :src="data.urlSppkp" />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>Invoice Image</span>
        </div>
        <div class="vx-col w-full">
          <input
            name="file_invoice"
            class="w-full inputx"
            type="file"
            ref="file_invoice"
            accept=".jpg, .jpeg, .png"
            @change="onImageChange($event, 'invoice')"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <img
            style="max-height: 170px; max-width: auto"
            :src="data.urlInvoice"
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>CL Image</span>
        </div>
        <div class="vx-col w-full">
          <input
            name="file_cl"
            class="w-full inputx"
            type="file"
            ref="file_cl"
            accept=".jpg, .jpeg, .png"
            @change="onImageChange($event, 'cl')"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <img style="max-height: 170px; max-width: auto" :src="data.urlCl" />
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          v-if="showButton"
          class="ml-4 mt-2"
          color="primary"
          v-on:click="handleApproval('approve')"
          >Approve</vs-button
        >
        <vs-button
          v-if="showButton"
          class="ml-4 mt-2"
          color="warning"
          v-on:click="handleApproval('reject')"
          >Reject</vs-button
        >
        <vs-button
          v-if="buttonReopen"
          class="ml-4 mt-2"
          color="primary"
          v-on:click="handleReopen"
          >Re-Open</vs-button
        >
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
    <!-- <div class="center">
      <vs-dialog width="300px" not-center v-if="activeDialog">
        <template #header>
          <h4 class="not-margin">
            Are you sure want to reject?
          </h4>
        </template>

        <div class="con-content">
          <vs-input v-model="textReason" placeholder="Reason"></vs-input>
        </div>

        <template #footer>
          <div class="con-footer">
            <vs-button @click="activeDialog=false" transparent>
              Submit
            </vs-button>
            <vs-button @click="activeDialog=false" dark transparent>
              Cancel
            </vs-button>
          </div>
        </template>
      </vs-dialog>
    </div> -->
    <vs-prompt
      @accept="onAcceptModalReject"
      :active.sync="modalReject.activeModalReject"
      title="Confirm"
      accept-text="Save"
    >
      <div class="con-exemple-prompt">
        <div class="">
          <p>Reason</p>
          <br />
        </div>
        <div>
          <multiselect
            class="selectExample"
            v-model="selectedReason"
            :options="optionReason"
            label="name"
            placeholder="type to search"
          />
        </div>
        <div v-if="data.showFreeText">
          <vs-input class="w-full" label="Reason" v-model="data.reasonReject" />
        </div>
      </div>
    </vs-prompt>

    <vs-prompt :active.sync="activeModalImage">
      <div class="con-exemple-prompt">
        <img
          style="max-height: auto; width: 1000px; margin: auto; display: block"
          :src="urlShow"
        />
      </div>
      <div>
        <vs-input
          class="w-full"
          :label="labelDialog"
          v-model="valueDialog"
          readonly
        />
      </div>
    </vs-prompt>
  </div>
</template>
<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import PaymentMethod from "./form-component/PaymentMethod";
import BussinessPartner from "./form-component/BussinessPartner";
import Status from "./form-component/Status";
import PriorityType from "./form-component/PriorityType";
import AddressComponent from "@/components/master/Address";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    PerfectScrollbar,
    PaymentMethod,
    BussinessPartner,
    Status,
    PriorityType,
    AddressComponent,
    Datepicker,
  },
  props: {
    customerId: {
      type: Number,
    },
    showButton: {
      type: Boolean,
    },
    showButtonReopen: {
      type: Boolean,
    },
    territoryData: {
      type: String,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    onImageChange(e, type) {
      const file = e.target.files[0];
      if (type == "ktp") {
        this.data.urlKtp = URL.createObjectURL(file);
      } else if (type == "invoice") {
        this.data.urlInvoice = URL.createObjectURL(file);
      } else if (type == "npwp") {
        this.data.urlNpwp = URL.createObjectURL(file);
      } else if (type == "sppkp") {
        this.data.urlSppkp = URL.createObjectURL(file);
      } else if (type == "outlet") {
        this.data.urlOutlet = URL.createObjectURL(file);
      } else if (type == "cl") {
        this.data.urlCl = URL.createObjectURL(file);
      }
    },
    showImage(type) {
      if (type == "KTP") {
        this.urlShow = this.data.urlKtp
        this.valueDialog = this.data.nik_name + " / " + this.data.nik
      } else if (type == "NPWP") {
        this.urlShow = this.data.urlNpwp
        this.valueDialog = this.data.npwp+ " / " + this.data.taxAddress
      } else if (type == "SPPKP") {
        this.urlShow = this.data.urlSppkp
        this.valueDialog = this.data.npwp+ " / " + this.data.taxAddress
      }

      this.labelDialog = type
      this.activeModalImage = true
      console.log(type)
    },
    nameWithCode ({ description, code }) {
      if (description != undefined) {
        return `${code} - ${description}`
      }
    },
    initialState() {
      return {
        buttonReopen: this.showButtonReopen,
        modalReject: {
          activeModalReject: false,
        },
        baseUrl: "/api/oms/v1/sfa-master/customer",
        baseUrlMatrixCg:
          "/api/oms/v1/sfa-master/matrix-customer-group-selected",
        selectedReason: "",
        urlShow: "",
        activeModalImage: false,
        valueDialog: "",
        labelDialog: "",
        optionReason: [
          {
            id: "Dokumen tidak lengkap - NPWP tidak jelas.",
            name: "Dokumen tidak lengkap - NPWP tidak jelas.",
          },
          {
            id: "Dokumen tidak lengkap - KTP tidak jelas.",
            name: "Dokumen tidak lengkap - KTP tidak jelas.",
          },
          {
            id: "Dokumen tidak lengkap - Invoice tidak jelas.",
            name: "Dokumen tidak lengkap - Invoice tidak jelas.",
          },
          {
            id: "Dokumen tidak lengkap - SK PKP tidak jelas.",
            name: "Dokumen tidak lengkap - SK PKP tidak jelas.",
          },
          {
            id: "Lokasi toko tidak sesuai - Alamat tidak lengkap.",
            name: "Lokasi toko tidak sesuai - Alamat tidak lengkap.",
          },
          {
            id: "Lokasi toko tidak sesuai - Longlat tidak sesuai dengan alamat.",
            name: "Lokasi toko tidak sesuai - Longlat tidak sesuai dengan alamat.",
          },
          {
            id: "Nama toko salah.",
            name: "Nama toko salah.",
          },
          {
            id: "Data toko tidak lengkap.",
            name: "Data toko tidak lengkap.",
          },
          {
            id: "Toko duplikat.",
            name: "Toko duplikat.",
          },
          {
            id: "Toko tidak valid.",
            name: "Toko tidak valid.",
          },
          {
            id: "Others.",
            name: "Others.",
          },
        ],
        data: {
          showfreeText: false,
          statusSubmit: true,
          parentID: null,
          customerGroupId: null,
          customerCategoryId: null,
          paymentTermId: null,
          paymentType: null,
          paymentMethodId: null,
          pricingGroupId: null,
          deliveryTypeId: null,
          customerGroupId1: null,
          customerGroupId2: null,
          customerGroupId3: null,
          dateOfBirth: null,
          code: "",
          codeExternal: "",
          name: "",
          legalName: "",
          searchName: "",
          contactName: "",
          phone: "",
          mobile: "",
          email: "",
          password: "",
          fax: "",
          isPkp: true,
          isTaxCollector: true,
          isTaxExempt: true,
          npwp: "",
          tdp: "",
          siup: "",
          npwpName: "",
          sppkp: "",
          domisili: "",
          taxAddress: "",
          nik: "",
          nik_name: "",
          photo: "",
          urlKtp: "",
          urlNpwp: "",
          urlSppkp: "",
          urlInvoice: "",
          urlOutlet: "",
          urlCl: "",
          invoiceModel: "When Shipping",
          priorityType: "Platinum",
          customerLimit: {
            amount: 0,
          },
          customerZone: {
            zoneId: null,
          },
          address: {
            address: "",
            postalCode: "",
            countryName: "",
            countryID: 0,
            provinceName: "",
            provinceID: 0,
            cityName: "",
            cityID: 0,
            transportZone: "",
            districtName: "",
            districtID: 0,
            subdistrictName: "",
            subdistrictID: 0,
            showWarningAddress: false,
          },
          rtRw: "",
          street: "",
          streetNo: "",
          note: "",
          reason: "",
          reasonReject: "",
          riskCategoryCode: "",
          regionSdCode: "",
        },
        zone: {
          selected: null,
          options: [],
        },
        city: {
          id: null,
          provinceId: null,
          name: "",
          transportZone: "",
        },
        customerCategory: {
          selected: null,
          options: [],
        },
        customerGroup1: {
          selected: null,
          options: [],
        },
        customerGroup2: {
          selected: null,
          options: [],
        },
        customerGroup3: {
          selected: null,
          options: [],
        },
        paymentTerm: {
          selected: null,
          options: [],
        },
        paymentType: {
          selected: null,
          options: ["Cash", "Credit"],
        },
        pricingGroup: {
          selected: null,
          options: [],
        },
        deliveryType: {
          selected: null,
          options: [],
        },
        territory: {
          selected: null,
          options: [],
        },
        sales: {
          selected: null,
          options: [],
        },
        priority: {
          selected: null,
          options: ["Platinum", "Gold", "Silver", "Bronze"],
        },
        riskCategory: {
          selected: null,
          options: [],
        },
        regionSd: {
          selected: null,
          options: [],
        },
      };
    },
    codeWithName({ name, code }) {
      if (name != undefined) {
        return `${code} - ${name}`;
      }
      return `${code}`;
    },
    isNumber(evt) {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "/",
      ];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    handleSubmit(status_approval) {
      this.$validator.validateAll().then((result) => {
        result = true;
        if (!this.data.customerCategoryId) {
          this.errors.add({
            field: "CustomerCategory",
            msg: "The Distribution Channel field is required",
          });
          result = false;
        }
        if (!this.data.regionSdCode) {
          this.errors.add({
            field: "RegionSd",
            msg: "The Region SD field is required",
          });
          result = false;
        }
        if (!this.data.customerGroupId1) {
          this.errors.add({
            field: "CustomerGroup1",
            msg: "The Customer Group 1 field is required",
          });
          result = false;
        }
        if (
          this.data.riskCategoryCode == null ||
          this.data.riskCategoryCode == ""
        ) {
          this.errors.add({
            field: "RiskCategory",
            msg: "The Risk Category field is required",
          });
          result = false;
        }
        if (!this.data.customerGroupId2) {
          this.errors.add({
            field: "CustomerGroup2",
            msg: "The Customer Group 2 field is required",
          });
          result = false;
        }
        if (!this.data.customerGroupId3) {
          this.errors.add({
            field: "CustomerGroup3",
            msg: "The Customer Group 3 field is required",
          });
          result = false;
        }
        if (!this.data.address.address) {
          this.data.address.showWarningAddress = true;
          this.errors.add({
            field: "Address",
            msg: "The Address field is required",
          });
          result = false;
        }
        if (!this.data.paymentTermId) {
          this.errors.add({
            field: "PaymentTerm",
            msg: "Payment Term field is required",
          });
          result = false;
        }
        if (!this.data.paymentType) {
          this.errors.add({
            field: "PaymentType",
            msg: "Payment Type field is required",
          });
          result = false;
        }
        if (!this.data.deliveryTypeId) {
          this.errors.add({
            field: "DeliveryType",
            msg: "Delivery Type field is required",
          });
          result = false;
        }
        if (this.data.isPkp) {
          if (!this.data.npwp) {
            this.errors.add({
              field: "NPWP",
              msg: "NPWP field is required.",
            });
            result = false;
          }
        }
        if (this.data.nik.length != 16) {
          this.errors.add({
            field: "NIK",
            msg: "NIK field must be 16 digits.",
          });
          result = false;
        }
        if (this.data.mobile.length > 13) {
          this.errors.add({
            field: "Mobile",
            msg: "The Mobile field may not be greater than 13 characters.",
          });
          result = false;
        }
        if (this.data.mobile.length < 10) {
          this.errors.add({
            field: "Mobile",
            msg: "The Mobile field at least 10 characters.",
          });
          result = false;
        }
        if (this.data.phone.length > 13) {
          this.errors.add({
            field: "Phone",
            msg: "The Phone field may not be greater than 13 characters.",
          });
          result = false;
        }
        if (this.data.phone.length < 10) {
          this.errors.add({
            field: "Phone",
            msg: "The Phone field at least 10 characters.",
          });
          result = false;
        }

        if (
          this.data.rtRw.length != 7 ||
          this.data.rtRw.indexOf("/") != 3 ||
          this.data.rtRw.split("/").length - 1 > 1
        ) {
          this.errors.add({
            field: "RT/RW",
            msg: "The RT / RW not valid format.",
          });
          result = false;
        }

        let amount = 0;
        if (
          this.data.customerLimit.amount != "" ||
          this.data.customerLimit.amount != null
        ) {
          amount = parseInt(
            this.data.customerLimit.amount.toString().replace(/\./g, "")
          );
        }

        if (amount < 0) {
          this.errors.add({
            field: "CreditLimit",
            msg: "The CreditLimit field cannot negatif value",
          });
          result = false;
        }
        if (this.data.isPkp && !this.data.taxAddress) {
          this.errors.add({
            field: "TaxAddress",
            msg: "The Tax Address field is required",
          });
          result = false;
        }

        if (result) {
          // var file = this.$refs.file.files[0];
          var file_ktp = this.$refs.file_ktp.files[0];
          var file_npwp = this.$refs.file_npwp.files[0];
          var file_sppkp = this.$refs.file_sppkp.files[0];
          var file_invoice = this.$refs.file_invoice.files[0];
          var file_outlet = this.$refs.file_outlet.files[0];
          var file_cl = this.$refs.file_cl.files[0];
          if (
            file_ktp != null ||
            file_npwp != null ||
            file_sppkp != null ||
            file_invoice != null ||
            file_outlet != null ||
            file_cl != null
          ) {
            var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
            var status_image = true;
            if (file_ktp != null && !validImageTypes.includes(file_ktp.type)) {
              status_image = false;
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: "File KTP must be an image",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }

            if (
              file_npwp != null &&
              !validImageTypes.includes(file_npwp.type)
            ) {
              status_image = false;
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: "File NPWP must be an image",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }

            if (
              file_sppkp != null &&
              !validImageTypes.includes(file_sppkp.type)
            ) {
              status_image = false;
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: "File SPPKP must be an image",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }

            if (
              file_invoice != null &&
              !validImageTypes.includes(file_invoice.type)
            ) {
              status_image = false;
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: "File INVOICE must be an image",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }

            if (
              file_outlet != null &&
              !validImageTypes.includes(file_outlet.type)
            ) {
              status_image = false;
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: "File OUTLET must be an image",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }

            if (file_cl != null && !validImageTypes.includes(file_cl.type)) {
              status_image = false;
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: "File CL must be an image",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }

            if (status_image) {
              // var name = this.data.code + "." + file.name.split(".").pop();
              // this.data.photo = name;
              this.formData = new FormData();
              if (file_ktp != null) {
                this.formData.append("file_ktp", file_ktp);
              }
              if (file_npwp != null) {
                this.formData.append("file_npwp", file_npwp);
              }
              if (file_sppkp != null) {
                this.formData.append("file_sppkp", file_sppkp);
              }
              if (file_outlet != null) {
                this.formData.append("file_outlet", file_outlet);
              }
              if (file_invoice != null) {
                this.formData.append("file_invoice", file_invoice);
              }
              if (file_cl != null) {
                this.formData.append("file_cl", file_cl);
              }

              this.$vs.loading();
              this.$http
                .post(
                  "api/oms/v1/sfa-master/customer-photo/" + this.customerId,
                  this.formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((resp) => {
                  this.$vs.loading.close();
                  if (resp.status == "success") {
                    if (this.customerId) {
                      this.putData(status_approval);
                    }
                    // else {
                    //   this.postData();
                    // }
                  } else {
                    this.$vs.notify({
                      color: "danger",
                      title: "Error",
                      text: resp.message,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle",
                    });
                  }
                });
            }
          } else {
            if (this.customerId) {
              this.putData(status_approval);
            }
            // else {
            //   this.postData();
            // }
          }
        }
      });
    },
    paramData() {
      let is_pkp = "2";
      if (this.data.isPkp) {
        is_pkp = "1";
      } else {
        this.data.npwp = "";
        this.data.tdp = "";
        this.data.siup = "";
        this.data.sppkp = "";
        this.data.domisili = "";
        this.data.taxAddress = "";
      }

      let is_tax_collector = "0";
      if (this.data.isTaxCollector) {
        is_tax_collector = "1";
      }
      let is_tax_exempt = "0";
      if (this.data.isTaxExempt) {
        is_tax_exempt = "1";
      }

      let amount = 0;
      if (this.data.customerLimit.amount != "") {
        amount = parseInt(
          this.data.customerLimit.amount.toString().replace(/\./g, "")
        );
      }
      return {
        parent_id: this.data.parentId,
        customer_group_id1: this.data.customerGroupId1,
        customer_group_id2: this.data.customerGroupId2,
        customer_group_id3: this.data.customerGroupId3,
        payment_term_id: this.data.paymentTermId,
        payment_type: this.data.paymentType,
        pricing_group_id: this.data.pricingGroupId,
        payment_method_id: this.data.paymentMethodId,
        customer_category_id: this.data.customerCategoryId,
        delivery_type_id: this.data.deliveryTypeId,
        //territory_ids: territoryIds,
        code: this.data.code,
        code_external: this.data.codeExternal,
        name: this.data.name,
        legal_name: this.data.legalName,
        search_name: this.data.searchName,
        //zone_id: this.data.customerZone.zoneId,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        rt_rw: this.data.rtRw,
        street: this.data.street,
        street_no: this.data.streetNo,
        postal_code: this.data.address.postalCode,
        contact_name: this.data.contactName,
        mobile: this.data.mobile,
        phone: this.data.phone,
        email: this.data.email,
        password: this.data.password,
        fax: this.data.fax,
        is_tax_collector: is_tax_collector,
        is_tax_exempt: is_tax_exempt,
        is_pkp: is_pkp,
        npwp: this.data.npwp,
        tdp: this.data.tdp,
        siup: this.data.siup,
        npwp_name: this.data.npwpName,
        sppkp: this.data.sppkp,
        domisili: this.data.domisili,
        tax_address: this.data.taxAddress,
        invoice_model: this.data.invoiceModel,
        status: this.data.status,
        type: "End Customer",
        priority_type: this.data.priorityType,
        amount: amount,
        nik: this.data.nik,
        nik_name: this.data.nik_name,
        photo: this.data.photo,
        date_of_birth: this.data.dateOfBirth,
        note: this.data.note,
        // reason: this.data.reason,
        risk_category_code: this.data.riskCategoryCode,
        region_sd_code: this.data.regionSdCode,
      };
    },
    // postData() {
    //   this.$vs.loading();
    //   this.$http
    //     .post(this.baseUrl, this.paramData())
    //     .then((resp) => {
    //       this.$vs.loading.close();
    //       if (resp.code == 200) {
    //         this.handleClose();
    //         this.$vs.notify({
    //           color: "success",
    //           title: "Success",
    //           text: "New Customer Created",
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //         this.$vs.loading.close();
    //       } else {
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //         this.$vs.loading.close();
    //       }
    //     })
    //     .catch((error) => {
    //       this.$vs.loading.close();
    //       console.log(error);
    //     });
    // },
    putData(status_approval) {
      this.$vs.loading();
      this.$http
        .put(this.baseUrl + "/" + this.customerId, this.paramData())
        .then((resp) => {
          if (resp.code == 200) {
            // this.handleClose();
            // this.$vs.notify({
            //   color: "success",
            //   title: "Success",
            //   text: "Customer Updated",
            //   position: "top-right",
            //   iconPack: "feather",
            //   icon: "icon-x-circle",
            // });

            this.$vs.loading.close();
            this.updateStatusApproval(status_approval);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    onAcceptModalReject() {
      if (this.data.reasonReject == "") {
        this.modalReject.activeModalReject = true;
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Reason is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.updateStatusApproval("3");
      }
    },
    handleReopen() {
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: "Confirm Reopen",
        text: "Are you sure want to reopen data?",
        accept: () => {
          this.$nextTick(() => {
            this.doReopenData();
          });
        },
      });
    },
    doReopenData() {
      console.log("update status approval");
      this.$vs.loading();
      this.$http
        .put(this.baseUrl + "/reopen/" + this.customerId)
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }

          this.$vs.loading.close();
          this.handleClose();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleApproval(status) {
      if (status == "approve") {
        var status_approval = "2";
        var message = "approve ?";
        var title = "Confirm Approve";

        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: title,
          text: "Are you sure want to " + message,
          accept: () => {
            this.$nextTick(() => {
              this.handleSubmit(status_approval);
            });
          },
        });
      } else {
        this.modalReject.activeModalReject =
          !this.modalReject.activeModalReject;
      }
    },
    updateStatusApproval(status_approval) {
      this.$vs.loading();
      this.$http
      .post(this.baseUrl + "/change-status/" + this.customerId, {
        status_approval: status_approval, // 2: approve, 3: reject
        reason_reject: this.data.reasonReject
      })
      .then((resp) => {
        if (resp.code == 200) {
          this.handleClose();
          this.$vs.notify({
            color: "success",
            title: "Success",
            text: "Success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }

          this.$vs.loading.close();
          this.handleClose();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.customerId) {
        this.$http
          .get(this.baseUrl + "/" + this.customerId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              console.log("===============created at : ", resp.data.CreatedAt);
              var a = moment(resp.data.CreatedAt);
              var b = moment(new Date());
              var diff = b.diff(a, "days");
              console.log("===============Diff Now : ", diff);
              if (diff > 30) {
                this.buttonReopen = false;
              }
              this.data.parentId = resp.data.parent_id;
              this.data.code = resp.data.code;
              this.data.codeExternal = resp.data.code_external;
              this.data.codeNoo = resp.data.code_noo;
              this.data.statusApproval = resp.data.status_approval;
              this.data.name = resp.data.name;
              // this.data.address = resp.data.address;
              this.data.contactName = resp.data.contact_name;
              this.data.phone = resp.data.phone;
              this.data.mobile = resp.data.mobile;
              this.data.email = resp.data.email;
              this.data.fax = resp.data.fax;
              this.data.paymentMethodId = resp.data.payment_method_id;
              this.data.nik = resp.data.nik;
              this.data.nik_name = resp.data.nik_name;
              this.data.photo = resp.data.photo + "?" + new Date().getTime();
              if (resp.data.invoice_model) {
                this.data.invoiceModel = resp.data.invoice_model;
              }
              if (resp.data.priority_type) {
                this.priority.selected = resp.data.priority_type;
              }
              if (resp.data.status) {
                this.data.status = resp.data.status;
              }
              // this.city.name = resp.data.city;
              this.data.address.address = resp.data.address;
              this.data.address.postalCode = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              this.data.address.subdistrictName = resp.data.sub_district;

              this.data.address.countryID = resp.data.country_id;
              this.data.address.provinceID = resp.data.province_id;
              this.data.address.cityID = resp.data.city_id;
              this.data.address.districtID = resp.data.district_id;
              this.data.address.subdistrictID = resp.data.sub_district_id;

              this.data.address.transportZone = resp.data.transport_zone;

              this.data.rtRw = resp.data.rt_rw;
              this.data.street = resp.data.street;
              this.data.streetNo = resp.data.street_no;
              this.data.legalName = resp.data.legal_name;
              this.data.searchName = resp.data.search_name;
              this.data.dateOfBirth = resp.data.date_of_birth;
              if (resp.data.is_tax_collector == "1") {
                this.data.isTaxCollector = true;
              } else {
                this.data.isTaxCollector = false;
              }
              if (resp.data.is_tax_exempt == "1") {
                this.data.isTaxExempt = true;
              } else {
                this.data.isTaxExempt = false;
              }
              if (resp.data.is_pkp == "1") {
                this.data.isPkp = true;
              } else {
                this.data.isPkp = false;
              }
              this.data.npwp = resp.data.npwp;
              this.data.tdp = resp.data.tdp;
              this.data.siup = resp.data.siup;
              this.data.npwpName = resp.data.npwp_name;
              this.data.sppkp = resp.data.sppkp;
              this.data.domisili = resp.data.domisili;
              this.data.taxAddress = resp.data.tax_address;
              this.data.customerLimit.amount = resp.data.customer_limit.amount;
              this.data.note = resp.data.note;
              this.data.reason = resp.data.reason.split("|");
              this.data.riskCategoryCode = resp.data.risk_category_code;
              this.data.regionSdCode = resp.data.region_sd_code;
              //this.data.customerZone.zoneId = resp.data.customer_zone.zone_id;

              this.customerCategory.selected = null;
              this.customerGroup1.selected = null;
              this.customerGroup2.selected = null;
              this.customerGroup3.selected = null;
              this.pricingGroup.selected = null;
              if (resp.data.customer_category_id) {
                this.getAllDataCustomerGroup1(resp.data.customer_category.name);
                this.setCustomerCategorySelected(
                  resp.data.customer_category_id
                );
              }

              // if (resp.data.customer_group_id1) {
              //   this.setCustomerGroup1Selected(resp.data.customer_group_id1);
              // }

              // if (resp.data.customer_group_id2) {
              //   this.setCustomerGroup2Selected(resp.data.customer_group_id2);
              // }

              // if (resp.data.customer_group_id3) {
              //   this.setCustomerGroup3Selected(resp.data.customer_group_id3);
              // }

              // if (resp.data.pricing_group_id) {
              //   this.setPricingGroupSelected(resp.data.pricing_group_id);
              // }
              if (
                resp.data.customer_group_id1 &&
                resp.data.customer_group_id2 &&
                resp.data.customer_group_id3 &&
                resp.data.pricing_group_id
              ) {
                this.setSelectedCustomerGroup(
                  resp.data.customer_group_id1,
                  resp.data.customer_group_id2,
                  resp.data.customer_group_id3,
                  resp.data.pricing_group_id
                );
              }

              if (resp.data.payment_term_id) {
                this.setPaymentTermSelected(resp.data.payment_term_id);
              } else {
                this.paymentTerm.selected = null;
              }

              if (resp.data.payment_type) {
                this.setPaymentTypeSelected(resp.data.payment_type);
              } else {
                this.data.paymentType = null;
                this.paymentType.selected = null;
              }

              if (resp.data.delivery_type_id) {
                this.setDeliveryTypeSelected(resp.data.delivery_type_id);
              } else {
                this.deliveryType.selected;
              }

              if (resp.data.risk_category_code) {
                this.setRiskCategorySelected(resp.data.risk_category_code);
              } else {
                this.data.riskCategoryCode = null;
                this.riskCategory.selected = null;
              }

              if (resp.data.region_sd_code) {
                this.setRegionSdSelected(resp.data.region_sd_code);
              } else {
                this.data.regionSdCode = null;
                this.regionSd.selected = null;
              }

              var setUrl = this.setUrl;
              resp.data.customer_image.forEach(function (item) {
                setUrl(item.description, item.photo_url);
              }, setUrl);

              // if (resp.data.customer_zone.zone_id) {
              //   this.sales.selected = null;
              //   this.setZoneSelected(resp.data.customer_zone.zone_id);
              //   this.getDataSales(resp.data.customer_zone.zone_id);
              // } else {
              //   this.zone.selected = [];
              //   this.sales.selected = null;
              // }

              // this.territory.selected = [];
              // var getDataTerritory = this.getDataTerritory;
              // resp.data.customer_territory.forEach(function (item) {
              //   getDataTerritory(item.territory_id);
              // }, getDataTerritory);

              // if (resp.data.sales_personal_id) {
              //   this.setSalesPersonalSelected(resp.data.sales_personal_id);
              // }
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    setSelectedCustomerGroup(cg1Id, cg2Id, cg3Id, pricingId) {
      this.$http.get(this.baseUrl + "-group/" + cg1Id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup1.selected = resp.data;
          this.$http.get(this.baseUrl + "-group/" + cg2Id).then((resp1) => {
            if (resp1.status == "success") {
              this.customerGroup2.selected = resp1.data;
              this.$http.get(this.baseUrl + "-group/" + cg3Id).then((resp2) => {
                if (resp2.status == "success") {
                  this.customerGroup3.selected = resp2.data;
                  this.$http
                    .get("/api/oms/v1/sfa-master/pricing-group/" + pricingId)
                    .then((resp3) => {
                      if (resp3.status == "success") {
                        this.pricingGroup.selected = resp3.data;
                      }
                    });
                }
              });
            }
          });
        }
      });
    },
    getAllDataCustomerCategory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-category-all", {
          params: {
            order: "name",
            sort: "asc",
            is_mobile: true,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerCategory.options = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataCustomerGroup1(customer_category_name = "") {
      console.log("getAllDataCustomerGroup1");
      this.$vs.loading();
      this.$http
        .get(this.baseUrlMatrixCg, {
          params: {
            allow_sfa: 1,
            level: 1,
            customer_category_name: customer_category_name,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup1.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataCustomerGroup2(cg1_code = "") {
      console.log("getAllDataCustomerGroup2");
      this.$vs.loading();
      this.$http
        .get(this.baseUrlMatrixCg, {
          params: {
            allow_sfa: 1,
            level: 2,
            cg1_code: cg1_code,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup2.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataCustomerGroup3(cg1_code = "", cg2_code = "") {
      console.log("getAllDataCustomerGroup3");
      this.$vs.loading();
      this.$http
        .get(this.baseUrlMatrixCg, {
          params: {
            allow_sfa: 1,
            level: 3,
            cg1_code: cg1_code,
            cg2_code: cg2_code,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup3.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataPricingGroup(cg1_code = "", cg2_code = "", cg3_code = "") {
      console.log("getAllDataPricingGroup");
      this.$vs.loading();
      this.$http
        .get(this.baseUrlMatrixCg, {
          params: {
            allow_sfa: 1,
            level: 4,
            cg1_code: cg1_code,
            cg2_code: cg2_code,
            cg3_code: cg3_code,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.pricingGroup.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataPaymentTerm() {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/payment-term", {
          params: {
            order: "name",
            sort: "asc",
            //payment_method_id: this.data.paymentMethodId,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.paymentTerm.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.zone.selected = {};
            this.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataTerritory(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/territory/" + id).then((resp) => {
        if (resp.status == "success") {
          this.territory.selected.push(resp.data);
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getAllDataDeliveryType() {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/delivery-type", {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.deliveryType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataRiskCategory() {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/customer-risk-category")
        .then((resp) => {
          if (resp.status == "success") {
            this.riskCategory.options = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataRegionSd() {
      this.$vs.loading();
      this.$http.get("/api/oms/v1/sfa-master/region-sd").then((resp) => {
        if (resp.status == "success") {
          this.regionSd.options = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getDataZone(ids) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/zone/territory", {
          params: {
            territory_ids: ids,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.zone.options = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    // getDataSales(zoneId) {
    //   this.$vs.loading();
    //   this.$http
    //     .get("/api/v1/master/customer-sales/zone/" + zoneId)
    //     .then((resp) => {
    //       if (resp.status == "success") {
    //         this.sales.options = resp.data;
    //         this.$vs.loading.close();
    //       } else {
    //         this.$vs.loading.close();
    //       }
    //     });
    // },
    getCode() {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-setting/master-number-code/CUST")
        .then((resp) => {
          if (resp.status == "success") {
            this.data.code = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setUrl(type, url) {
      if (type == "ktp") {
        this.data.urlKtp = url;
      } else if (type == "npwp") {
        this.data.urlNpwp = url;
      } else if (type == "sppkp") {
        this.data.urlSppkp = url;
      } else if (type == "outlet") {
        this.data.urlOutlet = url;
      } else if (type == "invoice") {
        this.data.urlInvoice = url;
      } else if (type == "cl") {
        this.data.urlCl = url;
      }
    },
    setCustomerCategorySelected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-category/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerCategory.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setPricingGroupSelected(id) {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/pricing-group/" + id)
        .then((resp) => {
          if (resp.status == "success") {
            this.pricingGroup.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setCustomerGroup1Selected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup1.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroup2Selected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup2.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroup3Selected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup3.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setPaymentTermSelected(id) {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/payment-term/" + id)
        .then((resp) => {
          if (resp.status == "success") {
            this.paymentTerm.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setPaymentTypeSelected(val) {
      this.data.paymentType = val;
      this.paymentType.selected = val;
    },
    // setZoneSelected(id) {
    //   this.$vs.loading();
    //   this.$http.get("/api/v1/master/zone/" + id).then((resp) => {
    //     if (resp.status == "success") {
    //       this.zone.selected = resp.data;
    //       this.$vs.loading.close();
    //     } else {
    //       this.$vs.loading.close();
    //     }
    //   });
    // },
    setDeliveryTypeSelected(id) {
      this.$vs.loading();
      this.$http
        .get("/api/oms/v1/sfa-master/delivery-type/" + id)
        .then((resp) => {
          if (resp.status == "success") {
            this.deliveryType.selected = resp.data[0];
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setRiskCategorySelected(val) {
      this.data.riskCategoryCode = val;
      this.riskCategory.selected = { code: val };
    },
    setRegionSdSelected(val) {
      this.data.regionSdCode = val;
      this.regionSd.selected = { code: val };
    },
    // setSalesPersonalSelected(id) {
    //   this.$vs.loading();
    //   this.$http.get("/api/v1/setting/personal/" + id).then((resp) => {
    //     if (resp.status == "success") {
    //       this.sales.selected = resp.data;
    //       this.$vs.loading.close();
    //     } else {
    //       this.$vs.loading.close();
    //     }
    //   });
    // },
    // pushToCustomerCategory() {
    //   this.$emit("tab", "Distribution Channel");
    // },
    // pushToPricingGroup() {
    //   this.$emit("tab", "Pricing Group");
    // },
    // pushToCustomerGroup() {
    //   this.$emit("tab", "Customer Group");
    // },
    // pushToPaymentTerm() {
    //   // this.$emit("tab", "Payment Term");
    //   this.$router.push("/master/payment-term");
    // },
    setPaymentMethodId(val) {
      this.data.paymentMethodId = val;
    },
    setParentId(val) {
      this.data.parentId = val;
    },
    setStatus(val) {
      this.data.status = val;
    },
    setDataAddress(val) {
      this.data.address = val;
    },
    autoFormatNPWP(NPWPString) {
      try {
        if (this.data.npwp.length == 21) {
          this.data.npwp = this.data.npwp.slice(0, -1);
        } else {
          var cleaned = ("" + NPWPString.toString()).replace(/\D/g, "");
          var match = cleaned.match(
            /(\d{0,2})?(\d{0,3})?(\d{0,3})?(\d{0,1})?(\d{0,3})?(\d{0,3})$/
          );
          this.data.npwp = [
            match[1],
            match[2] ? "." : "",
            match[2],
            match[3] ? "." : "",
            match[3],
            match[4] ? "." : "",
            match[4],
            match[5] ? "-" : "",
            match[5],
            match[6] ? "." : "",
            match[6],
          ].join("");
        }
      } catch (err) {
        return "";
      }
    },
  },
  mounted() {
    this.getAllDataCustomerCategory();
    // this.getAllDataPricingGroup();
    // this.getAllDataCustomerGroup1();
    // this.getAllDataCustomerGroup2();
    // this.getAllDataCustomerGroup3();
    this.getAllDataPaymentTerm();
    this.getAllDataDeliveryType();
    this.getAllDataRiskCategory();
    this.getAllDataRegionSd();
    //this.getAllDataTerritory();
    if (this.customerId) {
      this.getData();
    } else {
      this.getCode();
    }
  },
  computed: {
    // buttonReopen: function(){
    //   return this.showButtonReopen;
    // }
  },
  watch: {
    customerId(v) {
      this.getAllDataCustomerCategory();
      // this.getAllDataCustomerGroup1();
      // this.getAllDataCustomerGroup2();
      // this.getAllDataCustomerGroup3();
      this.getAllDataPaymentTerm();
      this.getAllDataDeliveryType();
      this.getAllDataRiskCategory();
      this.getAllDataRegionSd();
      //this.getAllDataTerritory();
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    "customerCategory.selected": function (val, oldVal) {
      if (val) {
        this.errors.clear();
        this.data.customerCategoryId = val.id ? val.id : val.ID;

        if (oldVal != null) {
          this.customerGroup1.selected = null;
          this.customerGroup2.selected = null;
          this.customerGroup3.selected = null;
          this.pricingGroup.selected = null;

          console.log("customer category name : ", val.name);
          this.getAllDataCustomerGroup1(val.name);
        }
      } else {
        this.data.customerCategoryId = null;
      }
    },
    "pricingGroup.selected": function (val, oldVal) {
      if (val) {
        this.errors.clear();
        this.data.pricingGroupId = val.id ? val.id : val.ID;
      } else {
        this.data.pricingGroupId = null;
      }
    },
    "customerGroup1.selected": function (val, oldVal) {
      if (val) {
        this.errors.clear();
        this.data.customerGroupId1 = val.id ? val.id : val.ID;
        if (
          this.customerId == null &&
          this.data.customerGroupId1 != null &&
          this.data.customerGroupId2 != null &&
          this.data.customerGroupId3 != null
        ) {
          this.$vs.loading();
          this.$http
            .get("/api/oms/v1/sfa-master/customer-group-limit/payment-term", {
              params: {
                customer_group_id1: this.data.customerGroupId1,
                customer_group_id2: this.data.customerGroupId2,
                customer_group_id3: this.data.customerGroupId3,
              },
            })
            .then((resp) => {
              if (resp.status == "success") {
                this.paymentTerm.selected = resp.data[0].payment_term;
                this.data.customerLimit.amount =
                  resp.data[0].records.credit_limit
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                this.$vs.loading.close();
              } else {
                this.$vs.loading.close();
              }
            });
        }

        // if (oldVal != null){
        if (this.customerCategory.selected != null) {
          this.customerGroup2.selected = null;
          this.customerGroup3.selected = null;
          this.pricingGroup.selected = null;
          this.$http
            .get("/api/oms/v1/sfa-master/risk-category/" + val.risk_category_id)
            .then((resp) => {
              if (resp.status == "success") {
                this.riskCategory.selected = resp.data;
              }
            });
          console.log("customer group 1 code : ", val.code);
          this.getAllDataCustomerGroup2(val.code);
        }
      } else {
        this.data.customerGroupId1 = null;
      }
    },
    "customerGroup2.selected": function (val, oldVal) {
      if (val) {
        this.errors.clear();
        this.data.customerGroupId2 = val.id ? val.id : val.ID;
        if (
          this.customerId == null &&
          this.data.customerGroupId1 != null &&
          this.data.customerGroupId2 != null &&
          this.data.customerGroupId3 != null
        ) {
          this.$vs.loading();
          this.$http
            .get("/api/oms/v1/sfa-master/customer-group-limit/payment-term", {
              params: {
                customer_group_id1: this.data.customerGroupId1,
                customer_group_id2: this.data.customerGroupId2,
                customer_group_id3: this.data.customerGroupId3,
              },
            })
            .then((resp) => {
              if (resp.status == "success") {
                this.paymentTerm.selected = resp.data[0].payment_term;
                this.data.customerLimit.amount =
                  resp.data[0].records.credit_limit
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                this.$vs.loading.close();
              } else {
                this.$vs.loading.close();
              }
            });
        }
        if (this.customerGroup1.selected != null) {
          this.customerGroup3.selected = null;
          this.pricingGroup.selected = null;
          console.log(
            "customer group 1 code : ",
            this.customerGroup1.selected.code
          );
          console.log("customer group 2 code : ", val.code);
          this.getAllDataCustomerGroup3(
            this.customerGroup1.selected.code,
            val.code
          );
        }
      } else {
        this.data.customerGroupId2 = null;
      }
    },
    "customerGroup3.selected": function (val, oldVal) {
      if (val) {
        this.errors.clear();
        this.data.customerGroupId3 = val.id ? val.id : val.ID;
        if (
          this.customerId == null &&
          this.data.customerGroupId1 != null &&
          this.data.customerGroupId2 != null &&
          this.data.customerGroupId3 != null
        ) {
          this.$vs.loading();
          this.$http
            .get("/api/oms/v1/sfa-master/customer-group-limit/payment-term", {
              params: {
                customer_group_id1: this.data.customerGroupId1,
                customer_group_id2: this.data.customerGroupId2,
                customer_group_id3: this.data.customerGroupId3,
              },
            })
            .then((resp) => {
              if (resp.status == "success") {
                this.paymentTerm.selected = resp.data[0].payment_term;
                this.data.customerLimit.amount =
                  resp.data[0].records.credit_limit
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                this.$vs.loading.close();
              } else {
                this.$vs.loading.close();
              }
            });
        }
        if (
          this.customerGroup1.selected != null &&
          this.customerGroup2.selected != null
        ) {
          this.pricingGroup.selected = null;
          console.log(
            "customer group 1 code : ",
            this.customerGroup1.selected.code
          );
          console.log(
            "customer group 2 code : ",
            this.customerGroup2.selected.code
          );
          console.log("customer group 3 code : ", val.code);
          this.getAllDataPricingGroup(
            this.customerGroup1.selected.code,
            this.customerGroup2.selected.code,
            val.code
          );
        }
      } else {
        this.data.customerGroupId3 = null;
      }
    },
    "paymentTerm.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.paymentTermId = val.id ? val.id : val.ID;
        this.data.paymentMethodId = val.payment_method_id;
      } else {
        this.data.paymentTermId = null;
      }
    },
    "data.paymentMethodId": function (val) {
      if (this.paymentTerm.selected && val !== undefined) {
        if (this.paymentTerm.selected.payment_method_id != val) {
          this.paymentTerm.selected = null;
        }
      }
      this.getAllDataPaymentTerm();
    },
    "deliveryType.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.deliveryTypeId = val.id ? val.id : val.ID;
      } else {
        this.data.deliveryTypeId = null;
      }
    },
    "priority.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.priorityType = val;
      } else {
        this.data.priorityType = null;
      }
    },
    "paymentType.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.paymentType = val;
      } else {
        this.data.paymentType = null;
      }
    },
    "riskCategory.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.riskCategoryCode = val.code;
      } else {
        this.data.riskCategoryCode = null;
      }
    },
    "regionSd.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.regionSdCode = val.code;
      } else {
        this.data.regionSdCode = null;
      }
    },
    "data.rtRw": function (val) {
      if (val.length == 8) {
        this.data.rtRw = this.data.rtRw.slice(0, -1);
      }
      if (
        val.length < 7 ||
        val.indexOf("/") != 3 ||
        val.split("/").length - 1 > 1
      ) {
        this.errors.add({
          field: "RT/RW",
          msg: "The RT / RW not valid format.",
        });
      } else if (val.length == 7) {
        this.errors.clear();
      }
    },
    "data.customerLimit.amount": function (val) {
      if (val != null) {
        val = val.toString().replace(/\./g, "");
        if (isNaN(parseInt(val))) {
          this.errors.add({
            field: "CreditLimit",
            msg: "The CreditLimit field is number required",
          });
        } else {
          this.errors.clear();
        }
        this.data.customerLimit.amount = val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        this.data.customerLimit.amount = 0;
      }
    },
    "data.isPkp": function (val) {},
    selectedReason: function (val) {
      if (val.name == "Others.") {
        this.data.showFreeText = true;
        this.data.reasonReject = "";
      } else {
        this.data.reasonReject = val.name;
        this.data.showFreeText = false;
      }
      console.log(val);
    },
    "data.npwp": function (val) {
      if (this.data.isPkp && !this.data.npwp) {
        this.errors.add({
          field: "NPWP",
          msg: "The NPWP field is required",
        });
      } else {
        this.errors.clear();
      }
    },
    "data.taxAddress": function (val) {
      if (this.data.isPkp && !this.data.taxAddress) {
        this.errors.add({
          field: "TaxAddress",
          msg: "The Tax Address field is required",
        });
      } else {
        this.errors.clear();
      }
    },
  },
};
</script>
<style>
.ps {
  height: 800px;
}
.vs-dialog {
  max-width: 1100px !important;
  height: auto;
}

/* .vs-dialog {
  max-width: max-content !important;
  max-height: auto !important;
} */
</style>
